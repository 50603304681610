<template>
  <div class="batchBox">
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 发放优惠券
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :span="20">
        <el-form ref="detail" label-position="right" label-width="140px" :model="detail">
          <el-form-item label="	发放类型:" prop="provide_type">
            <el-select
              class="w300"
              v-model="detail.provide_type"
              placeholder="请选择优惠券类型"
              @change="changeType"
            >
              <el-option
                v-for="item in options.provide_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
           <el-form-item v-if="detail.provide_type == 1" label="会员标签:">
            <el-select
              v-model="memberLabel"
              class="w300"
              clearable
              multiple
              filterable
              placeholder="请选择会员标签"
            >
              <el-option
                v-for="item in options.memberLabel"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="detail.provide_type == 2" label="店铺:">
            <el-select
              v-model="relationShop"
              class="w300"
              clearable
              multiple
              filterable
              placeholder="请选择店铺"
            >
              <el-option
                v-for="item in options.relationShop"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
            <el-form-item v-if="detail.provide_type == 3" label="会员等级:">
            <el-select
              v-model="memberRank"
              class="w300"
              clearable
              multiple
              filterable
              placeholder="请选择会员标签"
            >
              <el-option
                v-for="item in options.memberRank"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="detail.provide_type == 4" label="客户:">
            <el-select
              v-model="client"
              class="w300"
              clearable
              multiple
              filterable
              remote
              :remote-method="remoteMethod"
              placeholder="请输入客户手机号"
            >
              <el-option
                v-for="item in options.client"
                :key="item.id"
                :label="item.customer_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item v-if="detail.provide_type == 5" label="筛选条件:">
            门店归属：<br/>
            <el-select 
              class="w300" 
              v-model="search.shop_list" 
              multiple 
              placeholder="请选择门店归属"
            >
              <el-option
                v-for="item in options.relationShop"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
                >
              </el-option>
            </el-select>
            <br/>

            会员等级：<br/>
            <el-select class="w300" v-model="search.memberRank" multiple placeholder="请选择会员等级">
              <el-option
                v-for="item in options.memberRank"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >
              </el-option>
            </el-select>
            <br/>

            新增时间：<br/>
            <el-date-picker
              v-model="search.create_time"
              type="daterange"
              range-separator="至"
              :start-placeholder="this.search.create_start_time?this.search.create_start_time:'开始日期'"
              :end-placeholder="this.search.create_end_time?this.search.create_end_time:'结束日期'"
              value-format="yyyy-MM-dd"
              @change=""
            ></el-date-picker>
            <br/>

            进店时间：<br/>
            <el-date-picker
              v-model="search.come_time"
              type="daterange"
              range-separator="至"
              :start-placeholder="this.search.come_start_time?this.search.come_start_time:'开始日期'"
              :end-placeholder="this.search.come_end_time?this.search.come_end_time:'结束日期'"
              value-format="yyyy-MM-dd"
              @change=""
            ></el-date-picker>
            <br/>

            成交时间：<br/>
            <el-date-picker
              v-model="search.cj_time"
              type="daterange"
              range-separator="至"
              :start-placeholder="this.search.cj_start_time?this.search.cj_start_time:'开始日期'"
              :end-placeholder="this.search.cj_end_time?this.search.cj_end_time:'结束日期'"
              value-format="yyyy-MM-dd"
              @change=""
            ></el-date-picker>
            <br/>

            疤痕类型：<br/>
            <el-select class="w300" v-model="search.scar_id" multiple placeholder="请选择疤痕类型">
              <el-option
                v-for="item in options.scar_id"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <br/>

            客户来源：<br/>
            <el-select class="w300" v-model="search.source_id" multiple placeholder="请选择客户来源">
              <el-option
                v-for="item in options.source_id"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <br/>

            客户分类：<br/>
            <el-select class="w300" v-model="search.member_classification" multiple placeholder="请选择客户分类">
              <el-option
                v-for="item in options.member_classification"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <br/>

            客户标签：<br/>
            <el-select class="w300" v-model="search.member_label" placeholder="请选择客户标签">
              <el-option
                v-for="item in options.member_label"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <br/>

            保有流失：<br/>
            <el-select class="w300" v-model="search.baoyou" placeholder="请选择客户标签">
              <el-option
                v-for="item in options.baoyou"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <br/>
          </el-form-item>
          

          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm">完成，发放优惠券</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getShopList } from "@/api/shop";
import { ssettingList,clientList,getGradeList,getTwoLabel } from "@/api/client";
import { provide } from "@/api/card";

class Search{
  shop_list = [];
  memberRank = [];
  create_time = [];
  come_time = [];
  cj_time = [];
  scar_id = [];
  source_id = [];
  member_classification = [];
  member_label = "";
  baoyou = "";
}

class Detail {
  id = "";
  provide_type = 1;
  dataList = [];
  
}

class Options {
    provide_type = [
    { id: 1, name: "标签选择" },
    { id: 2, name: "门店选择" },
    { id: 3, name: "会员等级" },
    { id: 4, name: "指定会员" },
    { id: 5, name: "客户筛查" }
  ];
  effective_entry = [
    { id: 0, name: "全部" },
    { id: 1, name: "是" },
    { id: 2, name: "否" }
  ];
  baoyou = [
    { id: 1, name: "保有顾客" },
    { id: 2, name: "流失预警" },
    { id: 3, name: "流失顾客" },
  ];
  memberLabel = [];
  relationShop = [];
  memberRank = [];
  client = [];
  member_classification = [];
  source_id = [];
  scar_id = [];
  member_label = [];
}
export default {
  name: "issueCard",
  components: {},

  data() {
    return {
      search: new Search(),
      detail: new Detail(),
      options: new Options(), // 选项
      product: [], // 商品列表
      validData: [], // 有效期时间
      memberLabel: [], // 会员标签
      relationShop: [], // 选择的门店
      memberRank: [], // 会员等级
      client: [], // 选择的客户
      searchClient: [], //搜索集合
    };
  },
  mounted() {
    this.getOptioms('setting');
    this.detail.id = this.$route.query.id;
  },
  methods: {
    // 改变优惠券类型
    changeType(e) {
      this.relationShop = [];
      this.client = [];
      if(e == 2){
        this.getOptioms('shop');
      }else if(e == 3){
        this.getOptioms('rank');
      }else if(e == 5){
        this.getOptioms('shop');
        this.getOptioms('rank');
        this.getSetting();
      }
    },
    // 获取
    getSetting(){
      getTwoLabel().then(res => {
        this.options.member_label = res.data;
      });
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.scar_id.push(item);
          } else if (item.category_id == 4) {
            that.options.source_id.push(item);
          } else if (item.category_id == 5) {
            // that.options.repair_progress.push(item);
          } else if (item.category_id == 8) {
            that.options.member_classification.push(item);
          }
        });
      });
    },

    // 获取
    getOptioms(type) {
      switch (type) {
        case 'setting':
          ssettingList({ page: 1, limit: 100000 }).then(res => {
                let that = this;
                res.list.forEach(function(item) {
                    if (item.category_id == 7) {
                        that.options.memberLabel.push(item);
                    } 
                });
            });
          break;
        case 'shop':
          getShopList({ page: 1, limit: 100000 }).then(res => {
            this.options.relationShop = res.list;
          });
          break;
        case 'rank':
          getGradeList().then(res => {
            this.options.memberRank = res.list;
          });
          break;
      }
    },
    // 搜索客户
    remoteMethod(e) {
      if (e.length == 11) {
        clientList({ telephone: e, page: 1, limit: 100000 }).then(res => {
          this.options.client = res.list;
          this.searchClient = this.searchClient.concat(res.list);
        });
      }
    },
    // 确定新增商品
    submitForm() {
      if (this.detail.provide_type == 1) {
        if (this.memberLabel.length != 0) {
          let that = this;
          let newShop = [];
          that.options.memberLabel.forEach(function(val) {
            that.memberLabel.forEach(function(item) {
              if (item == val.id) {
                newShop.push(val);
              }
            });
          });
          this.detail.dataList = newShop.map(item => {
            return Object.assign(
              {},
              { data_id: item.id, data_name: item.name }
            );
          });

          if (that.detail.dataList) {
            this.hint("发放此优惠券");
          }
        } else {
          this.hintMes("会员标签");
        }
      } else if (this.detail.provide_type == 2) {
        if (this.relationShop.length != 0) {
          let that = this;
          let newShop = [];
          that.options.relationShop.forEach(function(val) {
            that.relationShop.forEach(function(item) {
              if (item == val.shopId) {
                newShop.push(val);
              }
            });
          });
          this.detail.dataList = newShop.map(item => {
            return Object.assign(
              {},
              { data_id: item.shopId, data_name: item.shopName }
            );
          });

          if (that.detail.dataList) {
            this.hint("发放此优惠券");
          }
        } else {
          this.hintMes("店铺");
        }
      }else if (this.detail.provide_type == 3) {
        if (this.memberRank.length != 0) {
          let that = this;
          let newShop = [];
          that.options.memberRank.forEach(function(val) {
            that.memberRank.forEach(function(item) {
              if (item == val.id) {
                newShop.push(val);
              }
            });
          });
          this.detail.dataList = newShop.map(item => {
            return Object.assign(
              {},
              { data_id: item.id, data_name: item.name }
            );
          });

          if (that.detail.dataList) {
            this.hint("发放此优惠券");
          }
        } else {
          this.hintMes("会员等级");
        }
      } else if (this.detail.provide_type == 4) {
        if (this.client.length != 0) {
          let that = this;
          let newShop = [];
          that.searchClient.forEach(function(val) {
            that.client.forEach(function(item) {
              if (item == val.id) {
                newShop.push(val);
              }
            });
          });
          this.detail.dataList = newShop.map(item => {
            return Object.assign(
              {},
              { data_id: item.id, data_name: item.customer_name }
            );
          });

          if (that.detail.dataList) {
            this.hint("发放此优惠券");
          }
        } else {
          this.hintMes("客户");
        }
      } else if (this.detail.provide_type == 5) {
        let newData = {};

        if(this.search.shop_list.length != 0){
          newData.shop_list = this.search.shop_list;
        }
        if(this.search.memberRank.length != 0){
          newData.memberRank = this.search.memberRank;
        }
        if(this.search.create_time.length != 0){
          newData.create_time = this.search.create_time;
          // this.detail.dataList.push({create_time:this.search.create_time});
        }

        if(this.search.come_time.length != 0){
          newData.come_time = this.search.come_time;
          // this.detail.dataList.push({come_time:this.search.come_time});
        }
        if(this.search.cj_time.length != 0){
          newData.cj_time = this.search.cj_time;
          // this.detail.dataList.push({cj_time:this.search.cj_time});
        }
        if(this.search.scar_id.length != 0){
          newData.scar_id = this.search.scar_id;
          // this.detail.dataList.push({scar_id:this.search.scar_id});
        }

        if(this.search.source_id.length != 0){
          newData.source_id = this.search.source_id;
          // this.detail.dataList.push({source_id:this.search.source_id});
        }
        if(this.search.member_classification.length != 0){
          newData.member_classification = this.search.member_classification;
          // this.detail.dataList.push({member_classification:this.search.member_classification});
        }
        if(this.search.member_label != 0){
          newData.member_label = this.search.member_label;
          // this.detail.dataList.push({member_label:this.search.member_label});
        }
        if(this.search.baoyou != 0){
          newData.baoyou = this.search.baoyou;
          // this.detail.dataList.push({member_label:this.search.member_label});
        }
        
        if (Object.keys(newData).length > 0 ) {
          this.detail.dataList = newData;
          this.hint("发放此优惠券");
        }
      }
    },
    // 提示
    hintMes(text) {
      this.$message({
        message: `请添加${text}哦~`,
        type: "warning"
      });
    },

    // 提示
    hint(text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确定新增/编辑
    confirm() {
       provide({ ...this.detail }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push("./cardList");
        });
    },

  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  position: relative;
  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
    .label2 {
      position: absolute;
      top: 270px;
    }
  }
  .rightCon {
    padding: 40px 0 0 300px;
    .btn {
      margin: 20px 0 50px 80px;
    }
    .mainBtn {
      margin: 30px 0 0 310px;
    }
    .phoneTip {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
